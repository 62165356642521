<template>
  <div class="home-page">
    <div class="flex space alcenter mt20 mb20">
      <a-button
        type="primary"
        style="margin-left: 24px"
        @click="showCerateStore = !0"
        >创建店铺</a-button
      >
    </div>
    <a-spin :spinning="loading">
      <div class="flex wrap alcenter">
        <div
          class="shop-item"
          v-for="(item, index) in mendian"
          :key="index"
          @click="goManage(item)"
        >
          <div class="shop-item-top flex alcenter">
            <div class="item-logo">
              <img :src="item.img" v-if="item.img" />
              <img src="../../assets/image/woyaoyou_head_defult@2x.png" v-else />
            </div>
            <div class="item-name text-over text-over1">
              {{ item.shop.name }}
            </div>
          </div>
          <div class="shop-item-bottom">
            <a-row class="pb10" v-if="item.shop.application">
              <a-col :span="7"> 店铺类型 </a-col>
              <a-col :span="2"></a-col>
              <a-col :span="14"> {{ item.shop.application.name }} </a-col>
            </a-row>
            <a-row class="pb10">
              <a-col :span="7"> 开通时间 </a-col>
              <a-col :span="2"></a-col>
              <a-col :span="14"> {{ item.add_time_format }} </a-col>
            </a-row>
          </div>
        </div>
      </div>
      <div class="flex end pd20">
        <a-pagination
          show-quick-jumper
          :defaultPageSize="pagination.pageSize"
          :show-total="(total) => `共 ${total} 条`"
          v-model="pagination.current"
          :total="pagination.total"
          @change="changePage"
        />
      </div>
    </a-spin>
    <create-store
      :visible="showCerateStore"
      @cancel="showCerateStore = !1"
      v-if="showCerateStore"
    ></create-store>
  </div>
</template>

<script>
import createStore from "./components/store/create";
export default {
  components: {
    createStore,
  },
  data() {
    return {
      loading: false,
      search: {
        name: "",
      },
      pagination: {
        current: 1,
        pageSize: 12, //每页中显示10条数据
        total: 0,
      },
      mendian: [],
      showCerateStore: !1,
    };
  },
  created() {
    this.loaddata();
  },
  methods: {
    loaddata() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .papi("platform/account/home", {
          limit: this.pagination.pageSize,
          page: this.pagination.current,
        })
        .then((res) => {
          this.mendian = res.mendian;
          this.pagination.total = res.mendian_total;
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    changePage(page) {
      this.pagination.current = page;
      this.loaddata();
    },
    goManage(record) {

				if(process.env.NODE_ENV=='development'){
          window.open(`${record.shop.application.development_address}auth?shop_token=${encodeURIComponent(record.shop_token)}`);
				}else if(process.env.NODE_ENV=='production'){
          window.open(`${record.shop.application.production_address}auth?shop_token=${encodeURIComponent(record.shop_token)}`);
				}else{
				}

    },
  },
};
</script>

<style lang="less">
.home-page {
  width: 1280px;
  margin: 0 auto;
  padding-bottom: 20px;
  .shop-item {
    cursor: pointer;
    position: relative;
    width: 285px;
    /* height: 340px; */
    background: #fff;
    border: 1px solid #d8e4ff;
    border-radius: 4px;
    margin-left: 24px;
    margin-bottom: 24px;
    .shop-item-top {
      height: 127px;
      background: #fbfcff;
      border-bottom: 1px solid #d8e4ff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 35px 15px;
      .item-logo {
        background: #fafafa;
        z-index: 2;
        margin-right: 20px;
        img {
          width: 58px;
          height: 58px;
          border-radius: 50%;
        }
      }
      .item-name {
        font-size: 16px;
        font-weight: 700;
        color: #292a31;
      }
    }
    .shop-item-bottom {
      padding: 25px 30px;
      color: #6f7078;
      font-size: 12px;
    }
  }
  .shop-item:hover {
    box-shadow: 2px 8px 10px #efefef;
  }
}
</style>
