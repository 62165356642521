<template>
  <div>
    <a-modal
      :title="getTitle"
      :width="640"
      :visible="visible"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="onSubmit"
          :loading="confirmLoading"
        >
          保存
        </a-button>
      </template>
      <a-spin :spinning="loading">
        <a-form-model
          :model="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item required label="店铺名称">
            <a-input
              style="width: 300px"
              placeholder="请输入店铺名称"
              v-model="form.name"
            />
          </a-form-model-item>
          <a-form-item required label="店铺类型">
            <a-select style="width: 300px" v-model="form.app_id">
              <a-select-option :value="0">请选择</a-select-option>
              <a-select-option
                :value="item.app_id"
                v-for="(item, index) in app_list"
                :key="index"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form-model>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      confirmLoading: false,
      form: {
        app_id: 0,
        name: "",
      },
      app_list: [],
    };
  },
  computed: {
    getTitle() {
      return "创建商铺";
    },
  },
  created() {
    this.loaddata();
  },
  methods: {
    loaddata() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .papi("platform/account/showApp", {
          type: 1,
        })
        .then((res) => {
          this.app_list = res.base_applications.concat(res.applications);
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    handleCancel() {
      this.$emit("cancel");
    },

    onSubmit() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .papi("platform/account/createShop", this.form)
        .then((res) => {
          this.$message.success("商铺创建成功", 1, () => {
            this.confirmLoading = false;
            this.handleCancel();
            window.open(
              `${res.base_url}auth?shop_token=${encodeURIComponent(
                res.shop_token
              )}`
            );
          });
        })
        .catch((res) => {
          console.log(res);
          this.confirmLoading = false;
        });
    },
  },
};
</script>

<style>
</style>
